<template>
    <div>
        <div style="position: relative" v-if="!planCheck && getted">
            <!-- <PlanSelectionVue /> -->
            <div class="d-flex flex-1 blured-div">
                <div class="wrapper">
                    <div class="text-center">
                        <h1 class="d-sm-none d-block">
                            Unlock Favorite Keywords with an Upgrade
                        </h1>
                        <h1 style="font-size: 3rem" class="d-none d-sm-block">
                            Unlock Favorite Keywords with an Upgrade
                        </h1>
                        <h4>
                            When you upgrade to Pro, you will be able to access keyword
                            search, niches, and more tools from AppLead.
                        </h4>
                    </div>
                    <b-button style="font-size: 2rem" variant="outline-success" to="/pages/plan-selection">
                        Upgrade Now
                    </b-button>
                </div>
            </div>
            <div class="container" style="filter: blur(1px)">
                <div class="container" style="padding-top: 30px;">
                    <div>
                        <div class="card">
                            <header role="tab" class="card-header p-1">
                                <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                                    <div class="d-flex align-items-center"><svg xmlns="http://www.w3.org/2000/svg"
                                            width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                            stroke-linejoin="round" class="feather feather-folder">
                                            <path
                                                d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z">
                                            </path>
                                        </svg><span class="ml-2"> Default </span></div>
                                    <div><button aria-expanded="true" aria-controls="collapse-4" title="hide collection"
                                            type="button" class="btn btn btn-icon rounded-circle btn-flat-success"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="20px" height="20px"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-eye-off">
                                                <path
                                                    d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24">
                                                </path>
                                                <line x1="1" y1="1" x2="23" y2="23"></line>
                                            </svg></button></div>
                                </div>
                            </header>
                            <div id="collapse-4" class="collapse show">
                                <div class="card-body">
                                    <div class="table-responsive">
                                        <table role="table" aria-busy="false" aria-colcount="3"
                                            class="table b-table table-striped" id="__BVID__133">
                                            <thead role="rowgroup" class="">
                                                <tr role="row" class="">
                                                    <th role="columnheader" scope="col" aria-colindex="1"
                                                        class="text-center">
                                                        <div>#</div>
                                                    </th>
                                                    <th role="columnheader" scope="col" aria-colindex="2"
                                                        class="text-center">
                                                        <div>keywords</div>
                                                    </th>
                                                    <th role="columnheader" scope="col" aria-colindex="3"
                                                        aria-label="Delete-keywords" class="text-right"><button
                                                            title="Remove keywords" type="button"
                                                            class="btn btn btn-icon rounded-circle btn-flat-danger"><svg
                                                                xmlns="http://www.w3.org/2000/svg" width="20px"
                                                                height="20px" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                class="feather feather-trash-2">
                                                                <polyline points="3 6 5 6 21 6"></polyline>
                                                                <path
                                                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                                                </path>
                                                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                                                <line x1="14" y1="11" x2="14" y2="17"></line>
                                                            </svg></button></th>
                                                </tr>
                                            </thead>
                                            <tbody role="rowgroup"><!---->
                                                <tr role="row" class="">
                                                    <td aria-colindex="1" role="cell" class="text-center"> 1 </td>
                                                    <td aria-colindex="2" role="cell" class="text-center" style="filter: blur(5px)"> paypal </td>
                                                    <td aria-colindex="3" role="cell" class="text-right"></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <td aria-colindex="1" role="cell" class="text-center"> 2 </td>
                                                    <td aria-colindex="2" role="cell" class="text-center" style="filter: blur(5px)"> paypal games
                                                        for money </td>
                                                    <td aria-colindex="3" role="cell" class="text-right"></td>
                                                </tr>
                                                <tr role="row" class="">
                                                    <td aria-colindex="1" role="cell" class="text-center"> 3 </td>
                                                    <td aria-colindex="2" role="cell" class="text-center" style="filter: blur(5px)"> paypal
                                                        business </td>
                                                    <td aria-colindex="3" role="cell" class="text-right"></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="planCheck && getted" style="overflow: hidden">
            <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login"
                modal-class="modal-session-app" centered title="Expired !" @ok="gotologin()">
                <b-card-text>Session Expired</b-card-text>
            </b-modal>
            <div class="container">
                <b-modal id="modal-session-app" ok-only ok-variant="warning" ok-title="Login"
                    modal-class="modal-session-app" centered title="Expired !" @ok="gotologin()">
                    <b-card-text>Session Expired</b-card-text>
                </b-modal>
                <div style="height: 95vh !important"
                    class="text-center d-flex flex-1 justify-content-center align-items-center" v-if="IsLoading">
                    <b-spinner variant="success" type="grow"></b-spinner>
                </div>
                <div v-if="collections.length === 0 && !IsLoading" class="nodataimg">
                    <img :src="noData" id="no-data" />
                    <h1>You don't have keywords collections</h1>
                </div>
                <div v-if="collections.length > 0">
                    <b-modal id="modal-warning-for-edit-collection" title="Edit Collection" centered hide-footer
                        modal-class="modal-warning" ok-title="Edit Collection" ref="refEditCollectionModal">
                        <div class="d-flex">
                            <b-form-input placeholder="Collection Name" v-model="collectionName">
                            </b-form-input>
                            <b-button class="ml-2" variant="warning" v-b-tooltip.hover.top title="Edit Collection"
                                @click="editCollection" style="padding: 5px 10px">
                                <feather-icon icon="EditIcon" size="18" />
                            </b-button>
                        </div>
                    </b-modal>
                    <b-modal id="modal-danger-for-delete-acc" ok-variant="danger" ok-title="Confirm"
                        modal-class="modal-danger" centered title="Delete Keywords From Collection"
                        @ok="removeKeywordsFromCollection">
                        <b-card-text>
                            Are you sure you want to delete keywords from your Collection ?
                        </b-card-text>
                    </b-modal>
                    <b-modal id="modal-danger-for-delete-col" ok-variant="danger" ok-title="Confirm"
                        modal-class="modal-danger" centered title="Delete" @ok="deleteCollection">
                        <b-card-text>
                            Are you sure you want to delete this collection ?
                        </b-card-text>
                    </b-modal>
                    <b-card v-for="collection in collections" :key="collection.id" no-body>
                        <b-card-header header-tag="header" class="p-1" role="tab">
                            <div class="flex-grow-1 d-flex align-items-center justify-content-between">
                                <div class="d-flex align-items-center">
                                    <feather-icon icon="FolderIcon" size="20" />
                                    <span class="ml-2">
                                        {{ collection.name }}
                                    </span>
                                </div>
                                <div>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-success"
                                        class="btn btn-icon rounded-circle" v-b-tooltip.hover.left
                                        :class="collection.isCollapsed ? null : 'collapsed'"
                                        :aria-expanded="collection.isCollapsed ? 'true' : 'false'"
                                        aria-controls="collapse-4" :title="collection.isCollapsed ? 'hide collection' : 'show collection'
                                            " @click="collection.isCollapsed = !collection.isCollapsed">
                                        <feather-icon :icon="collection.isCollapsed ? 'EyeOffIcon' : 'EyeIcon'"
                                            size="20" />
                                    </b-button>

                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-warning"
                                        class="btn btn-icon rounded-circle" v-b-tooltip.hover.top
                                        title="Edit Collection" v-if="collection.name != 'Default'"
                                        @click="editItemPopupCol(collection)">
                                        <feather-icon icon="EditIcon" size="20" />
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                                        class="btn btn-icon rounded-circle" v-b-tooltip.hover.right
                                        title="Delete Collection" v-if="collection.name != 'Default'"
                                        @click="removeItemPopupCol(collection.id)">
                                        <feather-icon icon="Trash2Icon" size="20" />
                                    </b-button>
                                </div>
                            </div>
                        </b-card-header>
                        <b-collapse id="collapse-4" v-model="collection.isCollapsed">
                            <b-card-body v-if="collection.keywords.length !== 0">
                                <b-table striped :items="collection.keywords" :fields="fields" responsive>
                                    <template #cell(index)="data">
                                        {{ data.index + 1 }}
                                    </template>
                                    <template #cell(name)="data">
                                        {{ data.item }}
                                    </template>

                                    <template #head(delete-keywords)>
                                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-danger"
                                            class="btn btn-icon rounded-circle" v-b-tooltip.hover.left
                                            title="Remove keywords" @click="removeItemPopupKeywords(collection.id)">
                                            <feather-icon icon="Trash2Icon" size="20" />
                                        </b-button>
                                    </template>
                                </b-table>
                            </b-card-body>
                            <b-card-body v-else>
                                <h6 class="text-center">No Data Here</h6>
                            </b-card-body>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </div>
        <div v-if="!getted">
            <LoaderVue />
        </div>
    </div>
</template>
<script>
import noData from "@/assets/images/NoData.png";
import {
    BTable,
    BAvatar,
    BCard,
    BRow,
    BCol,
    VBTooltip,
    BButtonGroup,
    BFormGroup,
    BPagination,
    BButton,
    BModal,
    VBModal,
    BCardText,
    BContainer,
    BFormSelect,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormInput,
    BSpinner,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getAccessTokenFromCookie } from "@/auth/utils";
import LoaderVue from "@/components/Loader/Loader.vue";
export default {
    components: {
        BFormInput,
        BCardHeader,
        BCardBody,
        BCollapse,
        BTable,
        BFormSelect,
        BFormGroup,
        BAvatar,
        VBTooltip,
        BCard,
        BButtonGroup,
        BRow,
        BCol,
        BPagination,
        BButton,
        BModal,
        VBModal,
        BCardText,
        BContainer,
        BSpinner,
        LoaderVue
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            imageSrc: require('../../assets/images/keyword_collections.png'),
            imageKey: new Date().getTime(),
            noData,
            collections: [],
            id: null,
            fields: [
                {
                    key: "index",
                    label: "#",
                    class: "text-center",
                },
                {
                    key: "name",
                    label: "keywords",
                    class: "text-center",
                },
                {
                    key: "delete-keywords",
                    label: "",
                    class: "text-right",
                },
            ],
            collectionName: null,
            IsLoading: true,
            userId: null,
            getted: false,
            planCheck: null,
        };
    },
    watch: {
        $route() {
            // Update imageKey when route changes to force Vue to reload the image
            this.imageKey = new Date().getTime();
        }
    },
    mounted() {
        this.checkUserPlan();
    },
    created() {
        const data = getAccessTokenFromCookie();
        this.userId = data[2].id;
        this.fetchCollections();
    },
    methods: {
        checkUserPlan() {
            axios
                .post("/checkUserSubscription")
                .then(({ data }) => {
                    this.getted = true;
                    this.planCheck = true;
                })
                .catch((err) => {
                    this.planCheck = false;
                    this.getted = true;
                });
        },
        gotologin() {
            this.$router.push({ name: "auth-login" });
        },
        removeItemPopupKeywords(item) {

            this.id = item;
            this.$root.$emit("bv::show::modal", "modal-danger-for-delete-acc");
        },
        removeItemPopupCol(id) {
            this.id = id;
            this.$root.$emit("bv::show::modal", "modal-danger-for-delete-col");
        },
        editItemPopupCol(item) {
            this.id = item.id;
            this.collectionName = item.name;
            this.$root.$emit("bv::show::modal", "modal-warning-for-edit-collection");
        },
        fetchCollections() {
            axios.get("/get-keywords-collections")
                .then(({ data }) => {
                    if (data && data.data) {
                        const newtab = data.data.map((collection) => {
                            return { ...collection, isCollapsed: true };
                        });
                        this.collections = newtab;
                        console.log(newtab)
                        this.IsLoading = false;
                    } else {
                        this.showToast(
                            "danger",
                            "AlertTriangleIcon",
                            "Error",
                            "faild to get collections"
                        );
                    }
                })
                .catch((error) => {
                    this.IsLoading = false;
                    console.error(error);
                });
        },
        removeKeywordsFromCollection() {
            axios.post("/destroy-keywords", {
                id: this.id,
            })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.showToast("success", "CheckIcon", "Success", data.message);
                        this.fetchCollections();
                    } else {
                        this.showToast("danger", "AlertTriangleIcon", "Error", data.message);
                    }
                });
        },
        editCollection() {
            axios.post("/update-collection", {
                id: this.id,
                name: this.collectionName,
            })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.showToast("success", "CheckIcon", "Success", data.message);
                        this.$refs.refEditCollectionModal.hide();
                        this.fetchCollections();
                    } else {
                        this.showToast("danger", "AlertTriangleIcon", "Error", data.message);
                    }
                });
        },
        deleteCollection() {
            axios.post("/destroy-collection", {
                id: this.id,
            })
                .then(({ data }) => {
                    if (data.status === "success") {
                        this.showToast("success", "CheckIcon", "Success", data.message);
                        this.fetchCollections();
                    } else {
                        this.showToast("danger", "AlertTriangleIcon", "Error", data.message);
                    }
                });
        },
        addIndexToTable(table) {
            const t = table.map((item, index) => {
                item.index = index + 1;
                return item;
            });
            return t;
        },
        showToast(variant, icon, title, text) {
            this.$toast({
                component: ToastificationContent,
                props: { title, icon, text, variant }
            });
        },
    },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#no-data {
    height: 100%;
    max-height: 800px;
    max-width: 400px;
    width: 100%;
}

.nodataimg {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.skel-dev {
    position: relative;
}

.message {
    background-color: #3b42538f;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;
    flex-direction: column;
    width: 100%;
}

.message p {
    font-size: 22px;
    margin-top: 10px;
}

.img-score {
    width: 25px;
    height: 25px;
}

.blured-div {
    position: absolute;
    background-color: black;
    opacity: 0.8;
    width: 100% !important;
    height: 70vh !important;
    z-index: 100;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    flex: 1;
    align-items: center;
    justify-content: center;
}
</style>
